@tailwind base;
@tailwind components;
@tailwind utilities;

/* Remove default margins and paddings */
body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #2d3748;
}

/* Ensure the App component takes full height */
.App {
  min-height: 100vh;
  background-color: #2d3748;
}

/* Button Styling */
.btn {
  @apply px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition;
}

/* Hand-drawn arrow animation */
@keyframes drawing {
  to {
    stroke-dashoffset: 0;
  }
}

/* Handwritten text hover effect */
.handwritten-callout {
  transition: transform 0.3s ease;
}

.handwritten-callout:hover {
  transform: scale(1.05) rotate(-8deg);
}